import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackLink from '../components/links/BackLink/BackLink'
import Search from '../components/Search/Search'
import { Article } from '../components/common.styled'
import useTranslation from '../hooks/useTranslation'

const SearchTemplate = ({data: {post, posts: {edges}}}) => {
  const {translate} = useTranslation()
  const backClickHandler = e => {
    e.preventDefault()
    window.history.back()
  }
  return (
    <Layout>
      <Seo title={translate('Search')} />
      <Article
        className="page search"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <BackLink id="search-back-button" uri={post.parent.node.uri} onClick={backClickHandler} />
          <h1 itemProp="headline">{translate('Search')}</h1>
        </header>
        <section itemProp="articleBody">
          <Search edges={edges} />
        </section>
      </Article>
    </Layout>
  )
}

export default SearchTemplate

export const pageQuery = graphql`
  query SearchQuery(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      parent: wpParent {
        node {
          uri
          slug
        }
      }
    }
    posts: allWpPage {
      edges {
        node {
          id
          uri
          title
          content
          menuOrder
          wpChildren {
            nodes {
              ... on WpPage {
                id
                uri
                title
                content
                menuOrder
              }
            }
          }
        }
      }
    }
  }
`
