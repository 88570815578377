import styled from 'styled-components'

export const Form = styled.form`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Input = styled.input`
padding: var(--spacing-2) var(--spacing-4);
border: 1px solid lightgray;
flex-grow: 1;
`

export const Button = styled.button`
padding: var(--spacing-2) var(--spacing-4);
border: 1px solid lightgray;
cursor: pointer;
`

export const Results = styled.div`
margin-top: var(--spacing-4);
padding-top: var(--spacing-4);
border-top: 1px solid lightgray;
`

export const ResultsList = styled.ul`
`

export const ResultsItem = styled.li`
`

export const ResultLink = styled.a`
`
