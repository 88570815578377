import React, { useState } from 'react'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useRecoilState } from 'recoil'
import { recoilSearchSubmitted, recoilSearchTerms, recoilSearchResults } from '../../recoil/app'
import { Form, Input, Button, Results, ResultsList, ResultsItem, ResultLink } from './Search.styled'
import useTranslation from '../../hooks/useTranslation'

const search = (lang, content, terms) => {
  const language = content.filter(obj => obj.node.uri.indexOf(`/${lang}/`) === 0)
  const results = language.reduce((arr, { node: { id, title, uri, content, wpChildren } }) => {
    if (content) {
      if (title.toLowerCase().includes(terms.toLowerCase()) || content.replace(/<img .*?>/g,"").toLowerCase().includes(terms.toLowerCase()))
        if (!arr.find(obj => obj.id === id))
          arr.push({ id, title, uri })
      if (wpChildren?.nodes && wpChildren.nodes.length)
        wpChildren.nodes.reduce((arr, { id, title, uri, content }) => {
          if ((title !== null && title.toLowerCase().includes(terms.toLowerCase())) || (content !== null && content.replace(/<img .*?>/g,"").toLowerCase().includes(terms.toLowerCase())))
            if (!arr.find(obj => obj.id === id))
              arr.push({ id, title, uri })
          return arr
        }, arr)
    }
    return arr
  }, []);
  return results;
}

const Search = ({ edges }) => {
  const [ terms, setTerms ] = useRecoilState(recoilSearchTerms)
  const [ submitted, setSubmitted ] = useRecoilState(recoilSearchSubmitted)
  const [ error, setError ] = useState(false)
  const [ results, setResults ] = useRecoilState(recoilSearchResults);
  const { lang, translate } = useTranslation()
  const inputChangeHandler = e => void setTerms(e.target.value.trimStart())
  const submitHandler = e => {
    e.preventDefault()
    setError(false)
    setSubmitted(true)
    const trimmed = terms.trim();
    if (trimmed.length < 3) {
      setError(true)
      setTerms(trimmed)
      setResults([])
      return
    } else if (trimmed.length < terms.length) {
      setTerms(trimmed)
    }
    setResults(search(lang, edges, trimmed))
  }
  return <>
    <Form role='search' onSubmit={submitHandler}>
      <Input onChange={inputChangeHandler} value={terms} type='search' placeholder={translate('Enter terms to search for')} />
      <Button type='submit'>{translate('Search')} <FontAwesomeIcon icon={faSearch} /></Button>
    </Form>
    {submitted &&
      <Results>
        {error ?
          <p>{translate('Please enter 3 or more characters')}</p> :
          results.length > 0 ?
            <ResultsList>
              {results.map(({ id, title, uri }, idx) =>
                <ResultsItem key={`ResultsItem/${idx}`}>
                  <ResultLink href={uri}>
                    {parse(title)}
                  </ResultLink>
                </ResultsItem>)}
            </ResultsList> :
            <p>{translate('No results found')}</p>}
      </Results>}
  </>
}

export default Search
